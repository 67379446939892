<template>
  <div class="params-setup__root">
    <Spinner v-if="isLoading" />
    <div
      v-if="isPlayerSetupPageFrozen"
      class="pricing-loading-overlay"
    >
      <p>{{ frozenPlayerSetupPageMessage }}</p>
    </div>
    <PlayerSetupHeader
      :allow-route-change="allowRouteChange"
      @goBack="onGoBack"
      @onTradingUIRoute="() => { isGoToTradingUIActive = true; }"
    />
    <ParamsTabs
      :tabs="paramsTabs"
      :active-tab="activeTab"
      @onTabChange="onTabChange"
      :main-header-tab="true"
    />
    <PlayerSetupHeaderScoreboard :selected-tab="activeTab.key" />
    <div
      class="param-manager-content"
    >
      <GameParameters
        v-if="activeTab?.key === 'game-params' && !isBaseballParams"
        :sport-id="sportId"
        :auto-mode="isSelectedTabAutoMode"
      />
      <BaseballGameParameters
        v-if="isBaseballParams"
        :sport-id="sportId"
        :active-tab="activeTab?.key"
        :auto-mode="isSelectedTabAutoMode"
      />
      <PlayerSetupContent v-if="activeTab?.key === 'player-params' && !isBaseballParams" />
    </div>
    <PlayerSetupFooter />
    <UnsavedChangesPopup
      v-if="isUnsavedChangesPopupActive"
      :message="'Any unsubmitted updates to players and data linked to an unset player will be lost. Are you sure you want to proceed?'"
      @onLeaveCancel="() => { isUnsavedChangesPopupActive = false; }"
      @onLeaveConfirm="confirmPageLeave"
    />
    <PlayersSelectionPrompt @onAssignMarkets="onAssignMarkets" />
    <BatchAddMarketsModal
      v-if="isAddMarketsModalActive"
      :is-visible="isAddMarketsModalActive"
      :available-markets="availableMarkets"
      :sport-id="sportId"
      @onAddSelectedMarkets="onAddMarketsModalClose"
      @onModalClose="onAddMarketsModalClose"
    />
  </div>
</template>

<script>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  watch,
  ref,
} from 'vue';
import { map, sortBy, includes } from 'lodash';
import { useStore } from 'vuex';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { useOnlineStatus } from '@/composables';
import PlayerSetupHeader from './PlayerSetupHeader';
import PlayerSetupContent from './PlayerSetupContent';
import PlayerSetupFooter from './PlayerSetupFooter';
import PlayerSetupHeaderScoreboard from './PlayerSetupHeaderScoreboard';
import ParamsTabs from './ParamsTabs';
import GameParameters from './GameParameters';
import BaseballGameParameters from './baseball/BaseballGameParameters';
import PlayersSelectionPrompt from './setup-by-team/modals/PlayersSelectionPrompt';
import Spinner from '@/components/common/Spinner';
import sportIds from '@/services/helpers/sports';
import UnsavedChangesPopup from '@/components/templates/sport-template/common/UnsavedChangesPopup';
import BatchAddMarketsModal from './setup-by-team/modals/BatchAddMarketsModal';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export default {
  components: {
    PlayerSetupHeader,
    PlayerSetupContent,
    PlayerSetupFooter,
    Spinner,
    UnsavedChangesPopup,
    PlayersSelectionPrompt,
    BatchAddMarketsModal,
    ParamsTabs,
    PlayerSetupHeaderScoreboard,
    GameParameters,
    BaseballGameParameters,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const isLoading = computed(() => store.getters.isPlayerSetupDataLoading);
    const isPlayerSetupPageFrozen = computed(() => store.getters.isPlayerSetupPageFrozen);
    const frozenPlayerSetupPageMessage = computed(() => store.getters.frozenPlayerSetupPageMessage || 'Simulating prices - please wait...');
    const sportId = computed(() => store.getters.playerSetupData?.sportId);
    const isUnsavedChangesActive = computed(() => store.getters.isUnsavedChangesActive);
    const isUnsavedChangesPopupActive = ref(false);
    const allowRouteChange = ref(false);
    const isGoToTradingUIActive = ref(false);
    const hasGlobalParams = computed(() => includes([HOCKEY_ID, BASKETBALL_ID, FOOTBALL_ID, BASEBALL_ID], sportId.value));
    const isBaseballParams = computed(() => sportId.value === BASEBALL_ID);

    const paramsTabs = computed(() => {
      const tabs = [
        {
          key: 'player-params',
          label: 'Player parameters',
          position: 2,
        },
      ];
      if (hasGlobalParams.value) {
        tabs.push({
          key: 'game-params',
          label: 'Game parameters',
          position: 1,
        });
      }
      return sortBy(tabs, (tab) => tab.position);
    });
    const selectedTab = ref(null);
    const activeTab = computed(() => selectedTab.value || paramsTabs.value[0]);
    const isSelectedTabAutoMode = computed(() => {
      let selectedMode;
      if (activeTab.value.key === 'player-params') {
        selectedMode = store.getters.playerParamsSelectedMode;
      } else if (activeTab.value.key === 'game-params') {
        selectedMode = store.getters.gameParamsSelectedMode;
      }
      return selectedMode === 'CALCULATED';
    });

    const isAddMarketsModalActive = ref(false);
    const availableMarkets = computed(() => {
      const markets = map(store.getters.availableMarkets, (market) => ({
        value: market.marketCode,
        label: market.marketName,
      }));
      return markets;
    });

    const onAddMarketsModalClose = () => {
      isAddMarketsModalActive.value = false;
    };

    const onAssignMarkets = () => {
      isAddMarketsModalActive.value = true;
    };

    watch(() => route.params.eventId, () => {
      if (!route.params.eventId) return;
      store.dispatch('loadPlayerSetupDetails', route.params.eventId);
      store.dispatch('setIsPlayerSetupEventSearchActive', false);
    });

    const preventPageLeaveIfEditing = (pageReload) => {
      if (!isUnsavedChangesActive.value) return false;
      if (pageReload) {
        return 'You have unsaved changes, do you want to continue?';
      }
      isUnsavedChangesPopupActive.value = true;
      return true;
    };

    const onGoBack = () => {
      router.back();
      store.dispatch('setGameParamsSelectedMode', '');
    };

    const confirmPageLeave = () => {
      allowRouteChange.value = true;
      if (isGoToTradingUIActive.value) {
        router.push({
          name: 'trading-ui',
          params: {
            eventId: route.params.eventId,
          },
        });
        isGoToTradingUIActive.value = false;
        return;
      }
      onGoBack();
    };

    onMounted(() => {
      store.dispatch('loadPlayerSetupDetails', route.params.eventId);
    });
    onBeforeUnmount(() => store.dispatch('unloadPlayerSetupDetails'));
    onBeforeRouteLeave(() => {
      if (preventPageLeaveIfEditing() && !allowRouteChange.value) { return false; }
      return true;
    });

    const onTabChange = (tab) => {
      selectedTab.value = tab;
    };

    watch(
      () => isUnsavedChangesActive.value,
      (newValue) => {
        if (newValue) {
          window.onbeforeunload = () => preventPageLeaveIfEditing(true);
        } else {
          window.onbeforeunload = null;
        }
      },
    );

    useOnlineStatus({
      onReconnect() {
        store.dispatch('reloadPlayerSetupDetails');
      },
    });

    return {
      isLoading,
      isPlayerSetupPageFrozen,
      isUnsavedChangesPopupActive,
      availableMarkets,
      isAddMarketsModalActive,
      frozenPlayerSetupPageMessage,
      paramsTabs,
      activeTab,
      sportId,
      isSelectedTabAutoMode,
      isGoToTradingUIActive,
      allowRouteChange,
      isBaseballParams,
      onGoBack,
      confirmPageLeave,
      onAssignMarkets,
      onAddMarketsModalClose,
      onTabChange,
    };
  },
};
</script>

<style lang="scss">
.params-setup__root {
  display: block;
  width: 100%;
  flex-basis: 100%;
  .param-manager-content {
    // Navbar - 69px
    // Header - 58px
    // Tabs - 48px
    // Scoreboard - 64px
    // Footer - 66px
    height: calc(100vh - 69px - 58px - 48px - 64px - 66px);
    padding: 0 16px;
    position: relative;
  }

  .pricing-loading-overlay {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(25, 20, 20, .7);
    z-index: $modalZIndex;
    p {
      padding: 16px;
      background-color: $white;
      width: 300px;
      border-radius: 4px;
    }
  }

  // Add markets modal
  .modal {
    .modal__dialog {
      min-width: 450px;
      max-width: 550px;
    }
    .modal__header {
      width: 100%;
      padding: 16px;
      gap: 16px;
      height: 82px;
      border-bottom: 1px solid $gray400;

      .modal__title {
        width: 100%;
        font-size: 16px;
      }

      .button {
        margin-top: -20px;
        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
    .no-players-message {
      padding: 16px 0 32px 0;
      text-align: center;
    }
    .modal__content {
      padding: 16px;
      .modal-data {
        height: calc(100% - 147px);
        max-height: 300px;
        overflow: auto;
        .search-field-container {
          background-color: $white;
          position: sticky;
          top: 0;
          padding: 1px 1px 4px 1px;
          z-index: 110;
        }
        .items-to-add {
          padding: 16px 7px;
          .checkbox-item {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 16px;

            .checkbox {
              width: 16px;
              height: 16px;
            }

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: $black;
              cursor: pointer;
            }
          }
        }
      }

      .modal-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 65px;
        padding: 16px;
        border-top: 1px solid $gray400;

        .modal-footer-right-section {
          display: flex;
          align-items: center;
          gap: 4px;
          .dismiss-btn {
            display: inline-block;
            padding: 4px 8px;
          }
        }

        span {
          cursor: pointer;
          &.deselect-active {
            color: $error500;
          }
        }
      }
    }
  }
}
</style>
